import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FilledButtonComponent } from '../../atoms/filled-button/filled-button.component';
import { CommonModule } from '@angular/common';
import { Sendout } from '../../../types';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateFnsModule } from 'ngx-date-fns';
import { SendoutStatusPipe } from '../../../pipes/SendoutStatus.pipe';

@Component({
  selector: 'app-card',
  standalone: true,
  imports: [
    FilledButtonComponent,
    CommonModule,
    MatTooltipModule,
    DateFnsModule,
    SendoutStatusPipe,
  ],
  templateUrl: './card.component.html',
  styleUrl: './card.component.css',
})
export class CardComponent implements OnInit {
  @Input() imgSrc: string = '';
  @Input() extraClass: string = '';
  @Input() brand: string = '';
  @Input() description: string = '';
  @Input() broadcastDate: Date | null = null;
  @Input() date: Date | null = null;
  @Input() btnText: string = '';
  @Input() borderColor: string = '';
  @Input() cardTitle: string = '';
  @Input() cardHeading: string = '';
  @Input() updatedAt: Date | null = null;
  @Input() user: string = '';
  @Output() buttonClick = new EventEmitter<void>();
  @Output() sendoutDateClick = new EventEmitter();
  @Input() variant: Sendout[] = [];

  onButtonClick() {
    this.buttonClick.emit();
  }

  onSendoutDateClick(event: Event, sendout: Sendout) {
    this.sendoutDateClick.emit(sendout);
    event.preventDefault();
  }

  ngOnInit(): void {}
}
