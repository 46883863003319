import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'filled-button',
  standalone: true,
  imports: [MatButtonModule, RouterLink],
  templateUrl: './filled-button.component.html',
  styleUrl: './filled-button.component.css',
})
export class FilledButtonComponent {
  @Input() routerLinkName: string = '';
  @Input() buttonText: string = '';
  @Input() className: string = '';
  @Input() disabled: boolean = false;
  @Output() childEvent = new EventEmitter();

  buttonClick() {
    this.childEvent.emit();
  }
}
