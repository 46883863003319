<div class="d-flex flex-column gap-4">
  <div class="card-container {{ extraClass }}" [style.--border-color]="borderColor">
    @if (imgSrc !== "") {
    <div class="w-100 card-img-container">
      <img src="{{ imgSrc }}" class="card-img-main" alt="card image" />
    </div>
    } @else {
    <div class="w-100 card-img-container dummy-bg"></div>
    }

    <div class="card-body position-relative w-100">
      <h5 class="card-title">{{ cardTitle }}</h5>
      @if (cardTitle !== 'Personlig aftale' && cardTitle !== 'Event' && cardTitle !== 'Brand' && cardTitle !== 'Konsultation' && cardTitle !== 'Sæson' && cardTitle !== 'Aktiviteter' && cardTitle !== 'Andet') {
      <h4 class="card-heading overflow-hidden h-49 two-line-truncate" [matTooltip]="cardHeading.length > 35 ? cardHeading : ''" [matTooltipPosition]="'above'">
        {{ cardHeading }}
      </h4>
      }
      @else {
      <h4 class="card-heading"> {{ cardHeading }} </h4>
      }

      @if (brand !== "" && description !== "") {
      <p class="brand-description">
        <strong>Brand:</strong> {{ brand }}
        <strong>Beskrivelse:</strong>
        {{ description.substring(0, 90) }}
        {{ description.length > 90 ? "..." : "" }}
      </p>
      }
      @if (broadcastDate) {
      <div class="d-flex flex-column broadcast-date-container">
        <span class="broadcast-date-default-text">Udsendelsesdato:
        </span>
        <span class="broadcast-date">{{ broadcastDate | dfnsFormat: 'd.M.yyyy' }}</span>
      </div>

      @if (updatedAt && user !== "") {
      <p class="broadcast-edit">
        Redigeret <span>{{ updatedAt | dfnsFormat: 'd.M.yyyy' }}</span> af
        <span>{{ user }}</span>
      </p>
      }
      }
      @if (date && cardTitle !== "Personlig aftale") {
      <p class="date">{{ date| dfnsFormat: 'd.M.yyyy' }}</p>
      }
      @if (variant.length > 0) {
      <div class="position-absolute event-creation-card-position">
        @for (sendout of variant; track sendout.id) {
        <div class="d-flex gap-2 align-items-center">
          <div class="variant-circle" [ngClass]="sendout.status | sendoutStatus: 'class'"></div>
          <span matTooltip="Udsendelse" [matTooltipPosition]="'above'" class="variant-text inactiveLink" (click)="onSendoutDateClick($event, sendout)">{{ sendout.sendoutDate | dfnsFormat: 'd.M.yyyy' }}</span>
        </div>
        }
      </div>
      }
    </div>
  </div>

  @if (btnText !== "") {
  <div class="w-100">
    <filled-button [buttonText]="btnText" className="card-btn" (childEvent)="onButtonClick()"></filled-button>
  </div>
  }
</div>
