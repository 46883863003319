import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  viewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { GroupedDataSet, TableColumns } from '../../../types';
import { DynamicTableComponent } from '../dynamic-table/dynamic-table.component';
import { CardComponent } from '../card/card.component';
import { CardsGroupComponent } from '../cards-group/cards-group.component';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-dynamic-accordion',
  standalone: true,
  imports: [
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    DynamicTableComponent,
    CardComponent,
    CardsGroupComponent,
    JsonPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './dynamic-accordion.component.html',
  styleUrl: './dynamic-accordion.component.css',
})
export class DynamicAccordionComponent<T> implements OnInit {
  accordion = viewChild.required(MatAccordion);
  @Input() columns: TableColumns[] = [];
  @Input() dataset!: GroupedDataSet<T>;
  @Input() showCards: boolean = false;
  @Input() cardClass: string = '';
  @Input() emptyStateText: string = '';
  @Output() clickEvent = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  handleDataClick = (data: any) => {
    this.clickEvent.emit(data);
  };
}
