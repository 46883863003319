import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { CardComponent } from '../card/card.component';
import { SendoutStatusPipe } from '../../../pipes/SendoutStatus.pipe';

@Component({
  selector: 'app-cards-group',
  standalone: true,
  imports: [CardComponent, SendoutStatusPipe],
  templateUrl: './cards-group.component.html',
  styleUrl: './cards-group.component.css',
})
export class CardsGroupComponent implements OnInit {
  @Input() cards: any[] = [];
  @Input() btnText: string = '';
  @Input() extraClass: string = 'margin-default';
  @Input() emptyStateText: string = '';
  @Input() cardTitle: string = '';
  @Output() clickEvent = new EventEmitter<any>();

  ngOnInit(): void {}

  buttonClick(data: any) {
    this.clickEvent.emit(data);
  }
}
