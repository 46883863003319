@if (dataset) {
<mat-accordion multi>
  @for (group of dataset.groups; track group.id; let first = $first) {
  <mat-expansion-panel [expanded]="first">
    <mat-expansion-panel-header>
      <mat-panel-title class="text-capitalize">
        {{ group.label }} ({{ group.entities.length }})
      </mat-panel-title>
    </mat-expansion-panel-header>

    @if (group.entities.length == 0) { <div class="empty-state-label">{{ emptyStateText }}</div>
    }
    @else {
    @if (showCards === false) {
    <div>
      <app-dynamic-table [columns]="columns" [data]="group.entities" (clickEvent)="handleDataClick($event)"></app-dynamic-table>
    </div>
    } @else {
    <div class="d-flex justify-content-start flex-wrap accordion-card-container mt-10">
      <app-cards-group [cardTitle]="'Card title?'" [emptyStateText]="emptyStateText" [extraClass]="cardClass" [cards]="group.entities" [btnText]="'row.btnText'" (clickEvent)="handleDataClick($event)"></app-cards-group>
    </div>
    }
    }
  </mat-expansion-panel>
  }
</mat-accordion>
}
